import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import {Container} from 'reactstrap'

export default ({ data }) => {
    const post = data.markdownRemark
    return (
        <Layout>
            <Container>
                <div style={{paddingTop:50}} dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`